import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Cart from "../Cart";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paperScrollPaper": {
    background: "#fff !important",
    width: "100%",
    maxWidth: "1300px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    background: "#eaeaea",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const CartModal = ({ open, handleClose, handleShow2 }) => {
  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            sx={{
              m: 0,
              p: 2,
              textAlign: "center",
              background: "#4A98B5",
              color: "#fff",
            }}
            id="customized-dialog-title"
          >
            JOB APPLICATION BASKET
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: "#fff",
            })}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Cart handleShow2={handleShow2} handleClose={handleClose} />
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
};

export default CartModal;
