import React from 'react';
import { Link } from 'react-router-dom'; 
import RecConsults from '../../../../../assets/images/user-defult.jpg'
import Verifyconsults from '../../../../../assets/images/verified.png'
// import { FaRegClock } from "react-icons/fa";



const ConsultCard = () => {
  return (
    <>
        <div className="dkg-consults-card  mb-5 mt-3 col-xl-10 col-lg-11 card">
            <div className="dkg-card-flexCon">
                <Link to="#" className="dkg-consults-experts-Con">
                    <div className="dkg-experts-first-cols">
                        <div className="dkg-consults-imgCon">
                            <img src={RecConsults} className='dkg-consults-img' alt="RecConsults" />
                        </div>
                    </div>
                </Link>
                <Link to="#" className="dkg-consults-profile-desc">
                    <h2 className="dkg-consultant-name">Full Name 
                        <span className='dkg-verify-consult'>
                            <img src={Verifyconsults} className='dkg-verify-consult-img' alt="Verify Consults" />
                        </span>
                    </h2>
                    <p className="dkg-consult-job-profile">DK Global Recruitment Ltd</p>
                    <div className="dkg-jobs-consult-keyboards">
                        <span className="dkg-jobs-consult-key">Recruiter</span>
                        <span className="dkg-jobs-consult-key">Entrepreneur</span>
                        <span className="dkg-jobs-consult-key">Career Consultant</span>
                        <span className="dkg-jobs-consult-key">Team Manager</span>
                        <span className="dkg-jobs-consult-key">Business Analyst</span>
                        <span className="dkg-jobs-consult-key">Business Developer</span>
                        <span className="dkg-jobs-consult-key">Frontend Developer</span>
                        <span className="dkg-jobs-consult-key">IT Product Manager</span>
                    </div>
                    <div className="dkg-consults-tabs-maincontainer">
                        <div className="dkg-consults-bioSec">
                            <div className="dkg-consult-bio">
                            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                            Many desktop publishing packages. Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
                              <Link to="/services/consultants/details" className="dkg-readmore-title">Read More...</Link>
                            </div>
                        </div>
                    </div>
                </Link>
                <Link to="#" className="dkg-consults-package-desc">
                    <h4 className="dkg-package-title">Credentials</h4>
                    <div className="dkg-package-cardcon">
                        <div className="dkg-package-card">
                            <div className="dkg-card-leftcon">
                                <h5 className='dkg-card-left-title'>Experience</h5>
                            </div>
                           
                        </div>
                        <div className="dkg-package-card">
                            <div className="dkg-card-leftcon">
                                <h5 className='dkg-card-left-title'>Qualifications</h5>
                               
                            </div>
                        </div>
                        <div className="dkg-package-card">
                            <div className="dkg-card-leftcon">
                                <h5 className='dkg-card-left-title'>Skills & Knowledge</h5>
                               
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="dkg-viewplans-btnCon">
                <Link to="/my-services/consultants/full-details" className='dkg-viewplans-btn'>View Full Profile</Link >
            </div>
        </div>
    </>
  )
}

export default ConsultCard