import React from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import LoginRoute from "./components/hocs/LoginRoute";
import MainPrivateRoute from "./components/hocs/MainPrivateRoute";
import PrivateRoute from "./components/hocs/PrivateRoute";
import MyaccountRoute from "./components/hocs/MyaccountRoute";
import Login from "./components/pages/login";
import candidatesLogin from "./components/pages/login/candidatelogin";

// Start My Account Module
import MyaccountDashboard from "./components/pages/myaccount/dashboard";
import MyaccountMailbox from "./components/pages/myaccount/mailbox/MyaccMailbox";
import MyaccountProfile from "./components/pages/myaccount/myprofile/MyProfile";
import MyaccResetLogin from "./components/pages/myaccount/resetlogin/ChangeLogin";
import MyaccDeleteAcc from "./components/pages/myaccount/setting/delete-acc/DeleteAccount";
import MyacctNotifiation from "./components/pages/myaccount/setting/notification/AccountNotifiation";
import MyaccContact from "./components/pages/myaccount/setting/contacts/MyaccContact";
import MyaccContactUs from "./components/pages/myaccount/setting/contact-us/ContactUs";
import MyaccFeedback from "./components/pages/myaccount/setting/feedback/MyaccFeedback";
import MyaccCookies from "./components/pages/myaccount/setting/cookies/MyaccCookies";
import MyaccTestTable from "./components/pages/myaccount/table/TestTable";
import MyaccPrivacy from "./components/pages/myaccount/privacy/MyaccPrivacy";
import MyaccTermsofUse from "./components/pages/myaccount/setting/terms/TermsofUse";
import MyaccMyfiles from "./components/pages/myaccount/setting/myfile/Myfiles";
import MyaccGdpr from "./components/pages/myaccount/privacy/GdprPrivacy";
// import MyaccServicesSummary from "./components/pages/myaccount/setting/overview/ServicesOverview";
import MyaccServicesOverview from "./components/pages/myaccount/setting/overview/ServicesOverview";
import MyaccAboutService from "./components/pages/myaccount/setting/aboutus/AboutService";
import MyaccServicesConsults from "./components/pages/myaccount/services/consult/Consults";
import MyaccServicesConsultDetail from "./components/pages/myaccount/services/consult/ConsultsDetails";
import MyaccServicesConsultFullDetail from "./components/pages/myaccount/services/consult/ConsultsfullDetails";

import MyaccServicespricing from "./components/pages/myaccount/services/pricing/Paidserviceprice";
import MyaccServicestestiomonial from "./components/pages/myaccount/services/testiomonial";
import MyaccServicesfeedbacks from "./components/pages/myaccount/services/feedbacks";
import MyaccServicesMyProfile from "./components/pages/myaccount/services/my-profile";
import MyaccServicessettings from "./components/pages/myaccount/services/settings";
import MyaccServicesfaq from "./components/pages/myaccount/services/faq/ServiceFaq";

import CommingsoonPage from "./components/pages/myaccount/commingsoon/CommingPage";
import MyaccBenfitService from "./components/pages/myaccount/services/benfits/BenfitService";

//import MyaccCandidates from "./components/pages/myaccount/facilities/Candidates";
import MyaccCallSms from "./components/pages/myaccount/facilities/CallSms";
import MyaccCRMLinesense from "./components/pages/myaccount/facilities/CRMLinesense";
import MyaccMSoffice from "./components/pages/myaccount/facilities/MSoffice";
import MyaccMSMailbox from "./components/pages/myaccount/facilities/MSMailbox";
import MyaccJobProfile from "./components/pages/myaccount/job-profile/JobProfile";
import MyaccPayment from "./components/pages/myaccount/payments/Payment";
import MyaccInvoice from "./components/pages/myaccount/invoices/Invoice";
import MyaccContract from "./components/pages/myaccount/contracts/Contract";
import MyaccFAQ from "./components/pages/myaccount/faq/Faq";
import MyaccKpi from "./components/pages/myaccount/applied/AppliedJobs";
import MyaccInterview from "./components/pages/myaccount/interviews/MyaccInterview";
import MyaccJoboffer from "./components/pages/myaccount/joboffer/MyaccJoboffer";
import MyaccJoined from "./components/pages/myaccount/joboffer/JoinedTbl";
import MyaccReferrals from "./components/pages/myaccount/referral/Referrals";
import MyaccInterviewPrep from "./components/pages/myaccount/interview-prep/InterviewPrep";

import MyaccMyresume from "./components/pages/myaccount/myresume/MyaccMyresume";
import MyaccLeaves from "./components/pages/myaccount/leaves/MyaccLeaves";
import MyaccProfileSuMM from "./components/pages/myaccount/myprofile/MyProfile";
import MyaccProfileAccInfo from "./components/pages/myaccount/myprofile/acc-info/AccountInfo";
import MyaccProfileContactInfo from "./components/pages/myaccount/myprofile/contact-info/ContactInfo";

import MyaccProfileContact from "./components/pages/myaccount/myprofile/contact/MyaccContact";
import MyaccResume from "./components/pages/myaccount/myprofile/resume/MyaccResume";
import MyaccCoverLetter from "./components/pages/myaccount/myprofile/cover/CoverLetter";
import MyaccUploadCv from "./components/pages/myaccount/myprofile/uploadcv/UploadCv";
import MyaccDownloadCv from "./components/pages/myaccount/myprofile/downloadcv/DownloadCv";

import MyaccMyProfile from "./components/pages/myaccount/myprofile/profile/MyProfile";
import MyaccMyApplications from "./components/pages/myaccount/myprofile/applications/MyApplications";
import MyaccPaidservover from "./components/pages/myaccount/paidservice/overview/Paidserveoverview";

import MyaccDocuments from "./components/pages/myaccount/myprofile/documents/Document";
import MyaccMyReferrals from "./components/pages/myaccount/myprofile/my-referrals/MyReferrals";
import MyaccMyServices from "./components/pages/myaccount/myprofile/my-services/MyServices";

import MyaccCartList from "./components/pages/myaccount/cart-list/Cart";

// import MyaccMyresume from './components/pages/myaccount/holidays/Holidays';
//End My Account Module

function App() {
  return (
    <div className="App">
      <Switch>
        {/* <LoginRoute exact path="/" component={Login} /> */}
        <LoginRoute exact path="/" component={candidatesLogin} />
        <MyaccountRoute path="/dashboard" component={MyaccountDashboard} />
        <MyaccountRoute path="/mailbox" component={MyaccountMailbox} />
        <MyaccountRoute path="/profile" component={MyaccountProfile} />
        <MyaccountRoute path="/test-table" component={MyaccTestTable} />
        <MyaccountRoute path="/settings/privacy" component={MyaccPrivacy} />
        <MyaccountRoute
          path="/settings/terms-of-use"
          component={MyaccTermsofUse}
        />
        <MyaccountRoute path="/settings/my-files" component={MyaccMyfiles} />
        <MyaccountRoute path="/settings/gdpr-explained" component={MyaccGdpr} />
        {/* <MyaccountRoute path="/emp-model/candidates" component={Myaccfacilities} /> */}
        <MyaccountRoute
          path="/settings/reset-logins"
          component={MyaccResetLogin}
        />
        <MyaccountRoute
          path="/settings/delete-account"
          component={MyaccDeleteAcc}
        />
        <MyaccountRoute
          path="/settings/notifications"
          component={MyacctNotifiation}
        />
        <MyaccountRoute path="/settings/about-us" component={MyaccContact} />
        <MyaccountRoute
          path="/settings/contact-us"
          component={MyaccContactUs}
        />
        <MyaccountRoute
          path="/settings/give-feedback"
          component={MyaccFeedback}
        />
        <MyaccountRoute path="/settings/cookies" component={MyaccCookies} />
        <MyaccountRoute path="/settings/faq" component={MyaccFAQ} />
        <MyaccountRoute
          path="/my-services/pricing-list"
          component={MyaccServicespricing}
        />
        <MyaccountRoute
          path="/my-services/testimonial"
          component={MyaccServicestestiomonial}
        />
        <MyaccountRoute
          path="/my-services/contact-us"
          component={MyaccServicesfeedbacks}
        />
        <MyaccountRoute
          path="/my-services/my-profile"
          component={MyaccServicesMyProfile}
        />
        <MyaccountRoute
          path="/my-services/settings"
          component={MyaccServicessettings}
        />
        <MyaccountRoute
          path="/my-services/faq-s"
          component={MyaccServicesfaq}
        />

        <MyaccountRoute
          path="/my-services/services"
          component={MyaccServicesOverview}
        />
        <MyaccountRoute
          path="/my-services/about-us"
          component={MyaccAboutService}
        />
        <MyaccountRoute
          path="/my-services/consultants/full-details"
          component={MyaccServicesConsultFullDetail}
        />
        <MyaccountRoute
          path="/my-services/consultants/details"
          component={MyaccServicesConsultDetail}
        />
        <MyaccountRoute
          path="/my-services/consultants"
          component={MyaccServicesConsults}
        />
        <MyaccountRoute
          path="/my-services/legal"
          component={MyaccBenfitService}
        />
        <MyaccountRoute
          path="/my-services/pass-interview"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/my-services/assessment"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/my-services/communication"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/my-services/using-internet"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/my-services/work-abroad"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/my-services/study-abroad"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/my-services/work-abroad"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/my-services/study-abroad"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/my-services/plan-carrer"
          component={CommingsoonPage}
        />
        <MyaccountRoute
          path="/emp-model/crm-license"
          component={MyaccCRMLinesense}
        />
        <MyaccountRoute path="/emp-model/calls-sms" component={MyaccCallSms} />
        <MyaccountRoute path="/emp-model/ms-office" component={MyaccMSoffice} />
        <MyaccountRoute
          path="/emp-model/ms-mailbox"
          component={MyaccMSMailbox}
        />
        <MyaccountRoute path="/open-jobs" component={MyaccJobProfile} />
        <MyaccountRoute path="/cart-list" component={MyaccCartList} />
        <MyaccountRoute path="/payments" component={MyaccPayment} />
        <MyaccountRoute path="/invoices" component={MyaccInvoice} />
        <MyaccountRoute path="/contracts" component={MyaccContract} />
        <MyaccountRoute path="/applied" component={MyaccKpi} />
        <MyaccountRoute path="/interviews" component={MyaccInterview} />
        <MyaccountRoute path="/offered" component={MyaccJoboffer} />
        <MyaccountRoute path="/joined" component={MyaccJoined} />
        <MyaccountRoute path="/interview-prep" component={MyaccInterviewPrep} />
        <MyaccountRoute path="/referrals" component={MyaccReferrals} />
        <MyaccountRoute path="/leaves" component={MyaccMyresume} />
        <MyaccountRoute
          path="/my-profile/summary"
          component={MyaccProfileSuMM}
        />
        <MyaccountRoute
          path="/my-profile/my-account"
          component={MyaccProfileAccInfo}
        />
        <MyaccountRoute
          path="/my-profile/my-contact"
          component={MyaccProfileContactInfo}
        />
        <MyaccountRoute
          path="/my-profile/contact"
          component={MyaccProfileContact}
        />
        <MyaccountRoute
          path="/my-services/home"
          component={MyaccPaidservover}
        />
        <MyaccountRoute
          path="/my-profile/my-profile"
          component={MyaccMyProfile}
        />
        <MyaccountRoute
          path="/my-profile/my-applications"
          component={MyaccMyApplications}
        />
        <MyaccountRoute
          path="/my-profile/my-desired-job"
          component={MyaccResume}
        />
        <MyaccountRoute
          path="/my-profile/my-dk-resume"
          component={MyaccCoverLetter}
        />
        <MyaccountRoute
          path="/my-profile/upload-cv"
          component={MyaccUploadCv}
        />
        <MyaccountRoute
          path="/my-profile/my-resume"
          component={MyaccDownloadCv}
        />
        <MyaccountRoute
          path="/my-profile/my-documents"
          component={MyaccDocuments}
        />
        <MyaccountRoute
          path="/my-profile/my-referrals"
          component={MyaccMyReferrals}
        />
        <MyaccountRoute
          path="/my-profile/my-dk-services"
          component={MyaccMyServices}
        />
      </Switch>
    </div>
  );
}

export default App;
