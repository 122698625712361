import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetData, PostData, PutData } from "../../services";

export const addNewJob = createAsyncThunk(
  "jobs/addnew",
  async (
    {
      com_id,
      category,
      company,
      project,
      role,
      lang,
      lang2,
      no_of_jobs,
      locality,
      job_country,
      location,
      job_profile,
      industry,
      work_permit,
      name,
      erf,
      lang_lvl,
      lang2_lvl,
      start_date1,
      start_date2,
    },
    thunkAPI
  ) => {
    const res = await PostData(true, "/jobs/jobsApi/add-new-job/", {
      com_id,
      category,
      company,
      project,
      role,
      lang,
      lang2,
      no_of_jobs,
      locality,
      job_country,
      location,
      job_profile,
      industry,
      work_permit,
      name,
      erf,
      lang_lvl,
      lang2_lvl,
      start_date1,
      start_date2,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addInstructions = createAsyncThunk(
  "jobs/add-instructions",
  async ({ job_id, description }, thunkAPI) => {
    const res = await PostData(true, "/jobs/jobsApi/add-instructions/", {
      job_id,
      description,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobCompanyList = createAsyncThunk(
  "jobs/live-jobs/companylist",
  async ({ isDelete, company }, thunkAPI) => {
    const res = await PostData(true, "/jobs/list/livejobs/companyname", {
      isDelete,
      company,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const jobList = createAsyncThunk(
  "jobs/live-jobs/list",
  async ({ isDelete, company, userId }, thunkAPI) => {
    const res = await PostData(true, "/jobs/list/livejobs", {
      isDelete,
      company,
      userId,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const closedlist = createAsyncThunk(
  "jobs/closed-jobs/list",
  async ({ isDelete }, thunkAPI) => {
    const res = await PostData(true, "/jobs/list/livejobs", { isDelete });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateJob = createAsyncThunk(
  "jobs/live-jobs/update",
  async ({ id, key, value }, thunkAPI) => {
    const res = await PutData(true, "/jobs/jobsApi/" + id, { key, value });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateJobWithTextBox = createAsyncThunk(
  "jobs/live-jobs/updatewithtextbox",
  async ({ id, key, value }, thunkAPI) => {
    const res = await PutData(true, "/jobs/jobsApi/input/" + id, {
      key,
      value,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateMiniSpecTab = createAsyncThunk(
  "jobs/live-jobs/mini_spec_tab",
  async ({ id, key, value }, thunkAPI) => {
    const res = await PutData(true, "/jobs/jobsApi/mini_spec_tab/" + id, {
      key,
      value,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateTitleBlocks = createAsyncThunk(
  "jobs/live-jobs/titleblocks",
  async ({ id, key, value }, thunkAPI) => {
    const res = await PutData(true, "/jobs/jobsApi/titleblocks/" + id, {
      key,
      value,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsDetails = createAsyncThunk(
  "jobs/live-jobs/details",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/jobs/jobsApi/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateLiveJobsCommentCount = createAsyncThunk(
  "jobs/live-jobs/commentlist/update-comment",
  async ({ id, value, commentAt }, thunkAPI) => {
    const res = await PutData(true, "/jobs/jobsApi/update-comment/" + id, {
      value,
      commentAt,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deletejobsRecords = createAsyncThunk(
  "jobs/delete-records",
  async ({ ids, isSoftDelete }, thunkAPI) => {
    const res = await PostData(true, "/jobs/list/livejobs/delete", {
      ids,
      isSoftDelete,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addToCart = createAsyncThunk(
  "jobs/add-to-cart",
  async ({ job_id, user_id }, thunkAPI) => {
    const res = await PostData(true, "/jobs/jobsApi/add-to-cart/", {
      job_id,
      user_id,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const cartList = createAsyncThunk(
  "jobs/cart-list",
  async ({ user_id }, thunkAPI) => {
    const res = await PostData(true, "/jobs/jobsApi/cart-list/", {
      user_id,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const allCartList = createAsyncThunk(
  "jobs/all-cart-list",
  async ({ user_id }, thunkAPI) => {
    const res = await PostData(true, "/jobs/jobsApi/all-cart-list/", {
      user_id,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addToCartDelete = createAsyncThunk(
  "jobs/delete-add-to-cart",
  async ({ job_id, user_id }, thunkAPI) => {
    const res = await PostData(true, "/jobs/jobsApi/delete-add-to-cart/", {
      job_id,
      user_id,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteCart = createAsyncThunk(
  "jobs/delete-cart",
  async ({ id, user_id, job_id }, thunkAPI) => {
    const res = await PostData(true, "/jobs/jobsApi/delete-cart/", {
      id,
      user_id,
      job_id,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

const jobsSlice = createSlice({
  name: "jobslist",
  initialState: {
    jobseDataList: [],
    cartDataList: "",
    allCartDataList: [],
    dataList: [],
    closedjobseDataList: [],
    jobDetails: {},
    isLoading: false,
    isUpdate: false,
    isCartUpdate: false,
    isError: false,
    isSuccess: false,
    isCartSuccess: false,
    message: "",
    isDetailsLoading: false,
    isCartLoading: false,
    isCartDeleteLoading: false,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isUpdate = false;
      state.isCartUpdate = false;
      state.isDetailsLoading = false;
      state.isSuccess = false;
      state.isCartSuccess = false;
      state.message = "";
      state.isCartLoading = false;
      state.isCartDeleteLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deletejobsRecords.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deletejobsRecords.fulfilled, (state, { payload }) => {
      state.closedjobseDataList = payload.result;
      state.message = payload.message;
      state.isLoading = false;
    });
    builder.addCase(deletejobsRecords.rejected, (state, action) => {
      state.isLoading = true;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(addToCart.pending, (state) => {
      state.isCartSuccess = false;
      state.isSuccess = true;
    });
    builder.addCase(addToCart.fulfilled, (state, action) => {
      let mainArray = state.jobseDataList;
      let finalResult = [];
      finalResult = mainArray.map((r) => {
        if (r.job_id === action.payload.result[0].job_id) {
          return action.payload.result[0];
        } else {
          return r;
        }
      });
      state.cartDataList = action.payload.numRows;
      state.isCartSuccess = true;
      state.jobseDataList = finalResult;
      state.message = "Job is added in cart.";
      state.isUpdate = true;
    });
    builder.addCase(addToCart.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isCartSuccess = false;
      state.message = "Something went wrong";
      state.isSuccess = true;
    });

    builder.addCase(addToCartDelete.pending, (state) => {
      state.isSuccess = false;
    });
    builder.addCase(addToCartDelete.fulfilled, (state, action) => {
      let mainArray = state.jobseDataList;
      let finalResult = [];
      finalResult = mainArray.map((r) => {
        if (r.job_id === action.payload.result[0].job_id) {
          return action.payload.result[0];
        } else {
          return r;
        }
      });
      state.isUpdate = true;
      state.cartDataList = action.payload.numRows;
      state.jobseDataList = finalResult;
    });
    builder.addCase(addToCartDelete.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(addNewJob.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(addNewJob.fulfilled, (state, { payload }) => {
      state.jobseDataList = payload.result;
      state.message = payload.message;
      state.isSuccess = payload.status;
      state.isLoading = false;
    });
    builder.addCase(addNewJob.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobCompanyList.pending, (state) => {
      state.isLoading = true;
      state.jobseDataList = [];
    });
    builder.addCase(jobCompanyList.fulfilled, (state, { payload }) => {
      state.jobseDataList = payload.result;
      state.message = payload.message;
      state.isLoading = false;
    });
    builder.addCase(jobCompanyList.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(jobList.fulfilled, (state, { payload }) => {
      state.jobseDataList = payload.result;
      state.message = payload.message;
      state.isLoading = false;
    });
    builder.addCase(jobList.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(closedlist.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(closedlist.fulfilled, (state, { payload }) => {
      state.closedjobseDataList = payload.result;
      state.message = payload.message;
      state.isLoading = false;
    });
    builder.addCase(closedlist.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(updateJob.pending, (state) => {});
    builder.addCase(updateJob.fulfilled, (state, action) => {
      let mainArray = state.jobseDataList;
      let finalResult = [];
      let finalResult1 = [];
      if (action.payload.result.status === "Closed") {
        finalResult = state.jobseDataList = state.jobseDataList.filter(
          (item) => item.id !== action.payload.result.id
        );
      } else {
        finalResult = mainArray.map((r) => {
          if (r.id === action.payload.result.id) {
            return action.payload.result;
          } else {
            return r;
          }
        });
      }

      if (action.payload.result.status !== "Closed") {
        finalResult1 = state.closedjobseDataList =
          state.closedjobseDataList.filter(
            (item) => item.id !== action.payload.result.id
          );
      } else {
        finalResult1 = mainArray.map((r) => {
          if (r.id === action.payload.result.id) {
            return action.payload.result;
          } else {
            return r;
          }
        });
      }

      state.isUpdate = true;
      state.jobseDataList = finalResult;
      state.closedjobseDataList = finalResult1;
      state.jobDetails = action.payload.result;
    });
    builder.addCase(updateJob.rejected, (state, action) => {
      state.isError = true;
      // state.message = action.error.message;
    });

    builder.addCase(jobsDetails.pending, (state) => {
      state.isDetailsLoading = true;
    });
    builder.addCase(jobsDetails.fulfilled, (state, { payload }) => {
      state.jobDetails = payload.result;
      state.isDetailsLoading = false;
    });
    builder.addCase(jobsDetails.rejected, (state, action) => {
      state.isDetailsLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(addInstructions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addInstructions.fulfilled, (state, { payload }) => {
      state.jobDetails.AdInstructions.unshift(payload.result);
      state.isUpdate = true;
      state.isDetailsLoading = false;
      state.message = payload.message;
    });
    builder.addCase(addInstructions.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(updateLiveJobsCommentCount.pending, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateLiveJobsCommentCount.fulfilled, (state, action) => {
      let mainArray = state.jobseDataList;
      state.isLoading = true;
      let finalResult = mainArray.map((r) => {
        if (r.id === action.meta.arg.id) {
          return {
            ...r,
            commentCount: action.meta.arg.value,
            commentAt: action.meta.arg.commentAt,
          };
        } else {
          return r;
        }
      });
      state.isUpdate = true;
      state.jobseDataList = finalResult;
    });
    builder.addCase(updateLiveJobsCommentCount.rejected, (state, action) => {
      state.isError = true;
      // state.message = action.error.message;
    });

    builder.addCase(cartList.pending, (state) => {
      state.isCartLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(cartList.fulfilled, (state, { payload }) => {
      state.cartDataList = payload.result;
      state.message = payload.message;
      state.isSuccess = payload.status;
      state.isCartLoading = false;
    });
    builder.addCase(cartList.rejected, (state, action) => {
      state.isCartLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(allCartList.pending, (state) => {
      state.isCartLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(allCartList.fulfilled, (state, { payload }) => {
      state.allCartDataList = payload.result;
      state.message = payload.message;
      state.isSuccess = payload.status;
      state.isCartLoading = false;
    });
    builder.addCase(allCartList.rejected, (state, action) => {
      state.isCartLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(deleteCart.pending, (state) => {
      state.isCartDeleteLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(deleteCart.fulfilled, (state, { payload }) => {
      state.allCartDataList = payload.result;
      state.message = payload.message;
      state.isSuccess = payload.status;
      state.isCartDeleteLoading = false;
      state.isCartUpdate = true;
      state.cartDataList = payload.fetchLastRecord;

      let mainArray = state.jobseDataList;
      let finalResult = [];
      finalResult = mainArray.map((r) => {
        if (r.job_id === payload.result1[0].job_id) {
          return payload.result1[0];
        } else {
          return r;
        }
      });
      state.isUpdate = true;
      state.jobseDataList = finalResult;
    });
    builder.addCase(deleteCart.rejected, (state, action) => {
      state.isCartDeleteLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });
  },
});
function isEmpty(obj) {
  for (var i in obj) {
    return false;
  }
  return true;
}
export const { clearState } = jobsSlice.actions;
export default jobsSlice.reducer;
