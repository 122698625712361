import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal'
import './apply-job.scss'
import { FaShareSquare } from "react-icons/fa";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { useDispatch, useSelector } from "react-redux";

import {
  HowToApplyDetails,
  updateFileMail,
  QuestionsList,
  addJobApplicationForm
} from "../../../../../../slice/jobSpec/jobsSlice";

import {
  jobList,
  cartList
} from "../../../../../../slice/jobs/jobsSlice";

import { sendInternalMail } from "../../../../../../slice/mailbox/mailboxSlice";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-paper':{
      maxWidth:"670px"
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    borderRadius:"5px",
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


const steps = ['Read Instructions', 'Screening Questions', 'Contact Details', 'GDPR Consent', "Agree TOB's"];


const emailBody = `<p style="color: rgb(51, 51, 51); font-family: Verdana, sans-serif;font-size:14px">Dear Applicant, </p>
<p style="color: rgb(51, 51, 51); font-family: Verdana, sans-serif;font-size:14px">Thank you for your job application.
</p>
<p style="color: rgb(51, 51, 51); font-family: Verdana, sans-serif;font-size:14px;margin:0;margin-bottom:5px">Please
    find attached PDF
    file for
    your reference.</p>
<p style="color: rgb(51, 51, 51); font-family: Verdana, sans-serif;font-size:14px;margin:0;margin-bottom:5px">We will
    review your
    job
    application
    within 24 hours and get back to you as soon as possible. </p>
<p style="color: rgb(51, 51, 51); font-family: Verdana, sans-serif;font-size:14px;margin:0;">If you are in hurry, please
    feel free
    to login into
    your account and start live chat with us.
    We would be happy to assist you immediately. </p>
<p style="color: rgb(51, 51, 51); font-family: Verdana, sans-serif;font-size:14px;margin-bottom:30px;margin-top:20px;">
    Wish you all the
    best in your job
    search! </p>
<p style="color: rgb(51, 51, 51); font-family: Verdana, sans-serif;font-size:14px;margin-bottom:5px">Regards, <br>
    DK Global Recruitment </p>
<p style="margin:0;margin-bottom:30px;"><img src="https://www.dkglobalrecruitment.com/sites/default/files/logo_0.png"
        width="120" /></p>`;

const ApplyjobPoup = ({ jobId, show, how, onHide, stepStatus,handleCloseCart }) => {
  const dispatch = useDispatch();

  const canEmail = useSelector(
    (state) => state.auth.userInfo.email
  );

  const { howToApplyDetails, questionsList, doc, isSuccess } = useSelector((state) => state.jobSpec);

  const [files, setFiles] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setFiles(doc);
    }
  }, [isSuccess]);

  useEffect(() => {
    setActiveStep(0)
  }, [onHide]);

  const [activeStep, setActiveStep] = useState(stepStatus);

  useEffect(() => {
    const fetchList = async () => {
      try {
        await dispatch(HowToApplyDetails({}));
      } catch (err) {
        console.log(err);
      }
    };
    fetchList();
  }, []);

  useEffect(() => {
    const fetchList1 = async () => {
      try {
        await dispatch(QuestionsList({}));
      } catch (err) {
        console.log(err);
      }
    };
    fetchList1();
  }, []);

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason == 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [state, setState] = useState({
    confirm2: false,
    confirm3: false,
    confirm4: false,
    confirm5: false,
    confirm6: false,
    confirm7: false,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  const { confirm2, confirm3, confirm4, confirm5, confirm6, confirm7 } = state;

  const [screeningQuestions, setScreeningQuestions] = useState({});
  const screeningQuestionshandleForm = e => {
    setScreeningQuestions({ ...screeningQuestions, [e.target.id]: e.target.value });
  };

  const [contactDetailsQuestions, setContactDetailsQuestions] = useState({});
  const contactDetailsQuestionshandleForm = e => {
    setContactDetailsQuestions({ ...contactDetailsQuestions, [e.target.id]: e.target.value });
  };

  const handleNext = () => {
    if (activeStep == 0) {
      if (confirm2 == true && confirm3 == true) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setOpen(true);
      }
    }
    if (activeStep == 1) {
      if (Object.keys(screeningQuestions).length > 0) {
        let checkValue = 0;
        Object.keys(screeningQuestions).map(function (key) {
          if (screeningQuestions[key] == "" || screeningQuestions[key] == " ") {
            checkValue = 1
          }
        });
        if (checkValue == 0) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          setOpen(true);
        }
      } else {
        setOpen(true);
      }
    }
    if (activeStep == 2) {
      if (Object.keys(contactDetailsQuestions).length == 6 && confirm7 == true) {
        let checkValue1 = 0;
        Object.keys(contactDetailsQuestions).map(function (key) {
          if (contactDetailsQuestions[key] == "" || contactDetailsQuestions[key] == " ") {
            checkValue1 = 1
          }
        });
        if (checkValue1 == 0) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          setOpen(true);
        }
      } else {
        setOpen(true);
      }
    }
    if (activeStep == 3) {
      if (confirm4 == true && confirm5 == true) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setOpen(true);
      }
    }
  };

  const { userInfo } = useSelector((state) => state.auth);

  const handleNext1 = async () => {
    if (activeStep == 4) {
      if (confirm6 == true) {
        setActiveStep((prevActiveStep) => prevActiveStep + 2);
        onHide();
        handleCloseCart();
        setConfirmOpen(true);
        let applicationData = await dispatch(
          addJobApplicationForm({
            values: jobId,
            canEmail:canEmail,
            screeningQuestions: screeningQuestions,
            contactDetailsQuestions: contactDetailsQuestions,
          })
        );
        const formData = new FormData();
        formData.append("from", "recruitment@dkgrecruitment.com");
        formData.append("to", jobId.email);
        formData.append("subject", "Your Job Application to - DK Global Recruitment");
        formData.append("body", emailBody);
        formData.append("category_id", 6);
        formData.append("candidate_id", jobId.user_id);
        // let internal_mailbox_id = await dispatch(sendInternalMail(formData));
        // await dispatch(updateFileMail({ internal_mailbox_id: internal_mailbox_id.payload.id, type: applicationData.payload.type, filename: applicationData.payload.filename, file_url: applicationData.payload.file_url }));
        setState({});
        setContactDetailsQuestions({});
        setScreeningQuestions({});
        setTimeout(() => setConfirmOpen(false), 30000);
        await dispatch(cartList({ user_id: userInfo.canId }));
        await dispatch(jobList({ isDelete: 1, userId:userInfo.canId }));
      } else {
        setOpen(true);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  let i = 0;
  let question_1 = "";
  let question_2 = "";
  let question_3 = "";
  if (activeStep == 2) {
    question_1 = <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingBottom: "7px" }} dangerouslySetInnerHTML={{ __html: howToApplyDetails.contact_details }}></div>;
    question_2 = questionsList.map((row, number) => {
      return (
        row.cat_type == 2 ?
          row.questions.map((row1, index) => {
            i++;
            let catTitle = <>{number != 0 ? <hr className='cat-line'></hr> : ""}<Typography sx={{ mt: 1 }} variant="h6" gutterBottom>
              {row.name}
            </Typography></>;
            return (
              <div className="col-12 pt-2 pb-2">
                {/* {index == 0 ? catTitle : ""} */}

                {
                  row1.id == "50" ? <><InputLabel sx={{ fontSize: "14px", color: "#000", fontWeight: "bold", whiteSpace: "break-spaces" }} htmlFor="input-with-icon-adornment">
                    {`Q${i} - ${row1.question}`}
                  </InputLabel>
                    <Input
                      id={row1.id}
                      defaultValue={contactDetailsQuestions[row1.id]}
                      className='question-input'
                      onChange={contactDetailsQuestionshandleForm}
                      sx={{ pl: 4, color: "#1976d2" }}
                      label="Multiline"
                      multiline
                      rows={4}
                      fullWidth
                    /></> : <>   <InputLabel sx={{ fontSize: "14px", color: "#000", whiteSpace: "break-spaces" }} htmlFor="input-with-icon-adornment">
                      {`Q${i} - ${row1.question}`}
                    </InputLabel>
                    <Input
                      id={row1.id}
                      defaultValue={contactDetailsQuestions[row1.id]}
                      className='question-input'
                      onChange={contactDetailsQuestionshandleForm}
                      sx={{ pl: 4, color: "#1976d2" }}
                      placeholder='Enter your answer'
                      fullWidth
                    /></>
                }

              </div>
            )
          })
          : ""
      )
    }

    );
  }
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  return (
    <>

<React.Fragment>
<BootstrapDialog
        onClose={handleConfirmClose}
        aria-labelledby="customized-dialog-title"
        open={confirmOpen}
        fullWidth={true}
      >
        {/* <IconButton
          aria-label="close"
          className='confirmBtn'
          onClick={handleConfirmClose}
          sx={{
            position: 'absolute',
            right: "-10px",
            top: "-12px",
            color:"#000",
            background:"#d9d9d9"
          }}
        >
          <CloseIcon sx={{fontSize:"16px"}} />
        </IconButton> */}
        <DialogContent  sx={{overflow:"hidden",background:"#26597d",border:"1px solid gray",borderRadius:"10px"}} dividers>
        <Typography component="div" sx={{borderRadius:"10px"}}>
                  <Typography variant="body1" align="center" sx={{justifyContent:"center",display:"flex"}}>
                  <svg class="my-tick" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                      <path class="circle" d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"/>
                      <path class="tick" d="M6.5 13.5L10 17 l8.808621-8.308621"/>
                    </g>
                  </svg>
                  </Typography>
                  <Typography variant="h5" sx={{ pb: 1,color:"#fff" }} align="center" gutterBottom>
                    Thank you!
                  </Typography>
                  <Typography variant="h5" sx={{ pb: 2,color:"#fff",fontSize:"1.3rem" }} align="center" gutterBottom>
                    Your application has been submitted successfully.<br></br>
                    Our recruiters will get in touch with you as soon as possible!<br></br>
                    We have sent you a copy of your application in your private mailbox.
                  </Typography>

                  {/* <Typography variant="body1" align="center" gutterBottom>
                    <Button sx={{ cursor: "unset !important" }} variant="outlined" size="large">
                      You will receive a copy of your application via email
                    </Button>
                  </Typography> */}
                  <Grid item md={12} xs={12} sm={12} sx={{ p: 0 }}>
                    <div className="confetti" style={{ margin: 0 }}>
                      <div className="confetti__wrapper">
                        <div className="confetti-left-50"></div>
                        <div className="confetti-left-49"></div>
                        <div className="confetti-left-48"></div>
                        <div className="confetti-left-47"></div>
                        <div className="confetti-left-46"></div>
                        <div className="confetti-left-45"></div>
                        <div className="confetti-left-44"></div>
                        <div className="confetti-left-43"></div>
                        <div className="confetti-left-42"></div>
                        <div className="confetti-left-41"></div>
                        <div className="confetti-left-40"></div>
                        <div className="confetti-left-39"></div>
                        <div className="confetti-left-38"></div>
                        <div className="confetti-left-37"></div>
                        <div className="confetti-left-36"></div>
                        <div className="confetti-left-35"></div>
                        <div className="confetti-left-34"></div>
                        <div className="confetti-left-33"></div>
                        <div className="confetti-left-32"></div>
                        <div className="confetti-left-31"></div>
                        <div className="confetti-left-30"></div>
                        <div className="confetti-left-29"></div>
                        <div className="confetti-left-28"></div>
                        <div className="confetti-left-27"></div>
                        <div className="confetti-left-26"></div>
                        <div className="confetti-left-25"></div>
                        <div className="confetti-left-24"></div>
                        <div className="confetti-left-23"></div>
                        <div className="confetti-left-22"></div>
                        <div className="confetti-left-21"></div>
                        <div className="confetti-left-20"></div>
                        <div className="confetti-left-19"></div>
                        <div className="confetti-left-18"></div>
                        <div className="confetti-left-17"></div>
                        <div className="confetti-left-16"></div>
                        <div className="confetti-left-15"></div>
                        <div className="confetti-left-14"></div>
                        <div className="confetti-left-13"></div>
                        <div className="confetti-left-12"></div>
                        <div className="confetti-left-11"></div>
                        <div className="confetti-left-10"></div>
                        <div className="confetti-left-9"></div>
                        <div className="confetti-left-8"></div>
                        <div className="confetti-left-7"></div>
                        <div className="confetti-left-6"></div>
                        <div className="confetti-left-5"></div>
                        <div className="confetti-left-4"></div>
                        <div className="confetti-left-3"></div>
                        <div className="confetti-left-2"></div>
                        <div className="confetti-left-1"></div>
                        <div className="confetti-left-0"></div>
                        <div className="confetti-right-40"></div>
                        <div className="confetti-right-49"></div>
                        <div className="confetti-right-48"></div>
                        <div className="confetti-right-47"></div>
                        <div className="confetti-right-46"></div>
                        <div className="confetti-right-45"></div>
                        <div className="confetti-right-44"></div>
                        <div className="confetti-right-43"></div>
                        <div className="confetti-right-42"></div>
                        <div className="confetti-right-41"></div>
                        <div className="confetti-right-40"></div>
                        <div className="confetti-right-39"></div>
                        <div className="confetti-right-38"></div>
                        <div className="confetti-right-37"></div>
                        <div className="confetti-right-36"></div>
                        <div className="confetti-right-35"></div>
                        <div className="confetti-right-34"></div>
                        <div className="confetti-right-33"></div>
                        <div className="confetti-right-32"></div>
                        <div className="confetti-right-31"></div>
                        <div className="confetti-right-30"></div>
                        <div className="confetti-right-29"></div>
                        <div className="confetti-right-28"></div>
                        <div className="confetti-right-27"></div>
                        <div className="confetti-right-26"></div>
                        <div className="confetti-right-25"></div>
                        <div className="confetti-right-24"></div>
                        <div className="confetti-right-23"></div>
                        <div className="confetti-right-22"></div>
                        <div className="confetti-right-21"></div>
                        <div className="confetti-right-20"></div>
                        <div className="confetti-right-19"></div>
                        <div className="confetti-right-18"></div>
                        <div className="confetti-right-17"></div>
                        <div className="confetti-right-16"></div>
                        <div className="confetti-right-15"></div>
                        <div className="confetti-right-14"></div>
                        <div className="confetti-right-13"></div>
                        <div className="confetti-right-12"></div>
                        <div className="confetti-right-11"></div>
                        <div className="confetti-right-10"></div>
                        <div className="confetti-right-9"></div>
                        <div className="confetti-right-8"></div>
                        <div className="confetti-right-7"></div>
                        <div className="confetti-right-6"></div>
                        <div className="confetti-right-5"></div>
                        <div className="confetti-right-4"></div>
                        <div className="confetti-right-3"></div>
                        <div className="confetti-right-2"></div>
                        <div className="confetti-right-1"></div>
                        <div className="confetti-right-0"></div>
                      </div>
                      <div className="banner"></div>
                    </div>
                  </Grid>
                </Typography>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>

      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={2000} open={open} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Please check all the mandatory fields.
        </Alert>
      </Snackbar>
      <Modal
        show={show}
        dialogClassName="dkg-applyjob-ModalDailog"
        aria-labelledby="example-custom-modal-styling-title"
        onHide={onHide}
      >
        <Modal.Header closeButton className="dkg-applyjob-header">
          <Modal.Title className="dk-detViewTitle" id="example-custom-modal-styling-title">
            JOB APPLICATION FORM
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='dkg-applyjob-modal-body dkg-applyjob-modalbody-234'>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel sx={{ background: "#fff", padding: "16px 0px 16px 0px" }} className='stepHeight'>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep == steps.length ? (
              ''
            ) : (
              <React.Fragment>
                  {activeStep == 0 ?
                    <>
                    <Typography component="div" className="step-div">
                      <div className='step-white-bg' style={{ height: "calc(100vh - 295px)", overflowY: "auto",paddingLeft: "16px", paddingRight: "16px", paddingTop: "10px" }} dangerouslySetInnerHTML={{ __html: activeStep == 0 ? howToApplyDetails.instructions : "" }}></div>
                      </Typography>

                      <FormGroup className='checkboxHeight' sx={{ background: "#fff", pl: 2, pr: 2, borderTop: "1px solid #bdbdbd"}}>
                        <FormControlLabel className='checkboxJob' sx={{ mb: 0, marginLeft: "-24px" }} required control={<Checkbox checked={confirm2} onChange={handleChange} name="confirm2" />} label="I confirm that I have read full job details and have no concerns about this job." />
                        <FormControlLabel className='checkboxJob' sx={{ mb: 0, marginLeft: "-24px" }} required control={<Checkbox checked={confirm3} onChange={handleChange} name="confirm3" />} label="I confirm that I am ready for interviews and if offered, I would accept this job." />
                      </FormGroup>
                    </>
                    :    
                    activeStep == 1 ? (
                      <Typography component="div" className="step-div">
                      <div className='step-white-bg2' style={{ height: "calc(100vh - 222px)",overflowY:"auto"}}>
                    {activeStep == 1 ? (
                      questionsList.map((row, number) => {
                        return (
                          row.cat_type == 1 ?
                            row.questions.map((row1, index) => {
                              i++;
                              let catTitle = <>{number != 0 ? <hr className='cat-line'></hr> : ""}<Typography sx={{ mt: 1 }} variant="h6" gutterBottom>
                                {row.name}
                              </Typography></>;
                              return (
                                <div className="col-12 pt-3 pb-1" key={row1.id}>
                                  {/* {index == 0 ? catTitle : ""} */}
                                  <InputLabel sx={{ fontSize: "14px", color: "#000", whiteSpace: "break-spaces" }} htmlFor="input-with-icon-adornment">
                                    {`Q${i} - ${row1.question}`}
                                  </InputLabel>
                                  <Input
                                    id={row1.id}
                                    defaultValue={screeningQuestions[row1.id]}
                                    className='question-input'
                                    onChange={screeningQuestionshandleForm}
                                    placeholder='Enter your answer'
                                    sx={{ pl: 4, color: "#1976d2" }}
                                    fullWidth
                                  />
                                </div>
                              )
                            })
                            : ""
                        )
                      }
  
                      )
                    ) : (
                      ''
                    )}
                    </div>
                  </Typography>) : ""}
                 

                  {activeStep == 2 ? (
                    <>
                    <Typography component="div" className="step-div">
                    <div className='step-white-bg1' style={{ height: "calc(100vh - 280px)",overflowY:"auto"}}>
                      {question_1}
                      {question_2}
                      {question_3}
                    </div>
                    </Typography>
                    </>
                  ) : (
                    ''
                  )}
                  {activeStep == 2 ? (
                    <FormGroup sx={{ background: "#fff", borderTop: "1px solid #bdbdbd", paddingTop: "9px", paddingBottom: "9px", }}>
                      <FormControlLabel className='checkboxJob' sx={{ mb: 0 }} required control={<Checkbox checked={confirm7} onChange={handleChange} name="confirm7" />} label="I agree that my communications can be recorded for training & quality purposes. " />
                    </FormGroup>
                  ) : (
                    ''
                  )}
                  {activeStep == 3 ?
                    <>
                     <Typography component="div" className="step-div">
                      <div className='step-white-bg3' style={{ height: "calc(100vh - 301px)",overflowY:"auto", paddingLeft: "16px", paddingRight: "16px" }} dangerouslySetInnerHTML={{ __html: activeStep == 3 ? howToApplyDetails.gdpr_consent : "" }}></div>
                      </Typography>
                      <FormGroup sx={{ background: "#fff", pl: 2, pr: 2, borderTop: "1px solid #bdbdbd" }}>
                        <FormControlLabel className='checkboxJob' sx={{ mb: 0, marginLeft: "-24px" }} required control={<Checkbox checked={confirm4} onChange={handleChange} name="confirm4" />} label={<span>I agree to the <a href="https://www.dkglobalrecruitment.com/privacy_policy" target='_blank'>privacy policy</a> of GDPR.</span>} />
                        <FormControlLabel className='checkboxJob' sx={{ mb: 0, marginLeft: "-24px" }} required control={<Checkbox checked={confirm5} onChange={handleChange} name="confirm5" />} label={<span>I agree to the <a href="https://www.dkglobalrecruitment.com/privacy_policy" target='_blank'>cookies policies</a> of GDPR.</span>} />
                      </FormGroup>
                    </>
                    : ""}
                  {activeStep == 4 ?
                    <>
                    <Typography component="div" className="step-div">
                      <div className='step-white-bg1' style={{ height: "calc(100vh - 280px)",overflowY:"auto", paddingLeft: "16px", paddingRight: "16px", paddingTop: "10px" }} dangerouslySetInnerHTML={{ __html: activeStep == 4 ? howToApplyDetails.agree_tob_s : "" }}></div>
                      </Typography>
                      <FormGroup sx={{ background: "#fff", borderTop: "1px solid #bdbdbd", paddingTop: "9px", paddingBottom: "9px", }}>
                        <FormControlLabel className='checkboxJob' sx={{ mb: 0 }} required control={<Checkbox checked={confirm6} onChange={handleChange} name="confirm6" />} label="I agree to the terms & conditions of DK Global services." />
                      </FormGroup>
                    </>
                    : ""}
                  {activeStep == 5 ? '' : ""}
                <Box sx={{ display: 'flex', justifyContent: "center", flexDirection: 'row', padding: "18px", background: "#fff", borderTop: "1px solid #bdbdbd" }}>

                  {activeStep != 0 ? (
                    <Button
                      sx={{ color: "#000", mr: 3 }}
                      disabled={activeStep == 0}
                      onClick={handleBack}
                      startIcon={<ArrowBackIosIcon />}
                    >
                      Go Back
                    </Button>
                  ) : ''}

                  {activeStep >= 4 ? (
                    <Button className='step-btn' variant="contained" onClick={handleNext1}>
                      Finish
                    </Button>

                  ) : <Button className='step-btn' endIcon={<ArrowForwardIosIcon />} variant="contained" onClick={handleNext}>
                    Next
                  </Button>}
                </Box>
              </React.Fragment>
            )}
          </Box>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default ApplyjobPoup