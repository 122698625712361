import React from 'react';
import  './interview-prep.scss'

const InterviewPrep = () => {
  return (
    <>
    <div className="dkg-interview-prep-main-con container-fluid">
         <div className="dkg-myacc-interview-prep-mainRow">
          <div className="dkg-myacc-interview-prep-mainCol">
            <div className="dkg-myacc-interview-cardCon">
              <div className="dkg-myacc-interview-prep-Header">
                <h2 className="dkg-interview-prep-title">Interviews Preparation</h2>
                <div className="dkg-interview-prep-content">
                  <p className='dkg-interview-prep-para'>
                  If you need help and guidance to prepare for your interviews, consider using our SharePoint Interview preparation portal. This portal is designed to provide you with tools, resources, and knowledge to confidently ace your interviews. You will find detailed insights into the hiring company’s interview process, sample questions and resources to help you prepare effectively, along with step-by-step breakdowns of the interview stages, timelines, and feedback processes.
                  </p>
                </div>
              </div>
            </div>
            <div className="dkg-myacc-preprations-sec">
            <div className="dkg-myacc-interview-prep-Body">
                <div className="dkg-interview-prep-sec row" style={{borderBottom: "1px solid #676766"}}>
                  <div className="col-lg-6 col-md-6 dkg-interview-prep-left-con col-sm-12">
                     <h2 className="dkg-interview-prep-heading">For SharePoint Site Access?</h2>
                     <div className='dkg-interview-prep-content'>
                      <p>To access the interview preparation site, simply email us or use live chat on our website or your Jobseeker account. Let us know if you’d like access to the interview preparation resources. We’ll also offer this option to you once you reach the interview stage.</p>
                      <p>Note: Not all candidates need extra preparation, as interview difficulty can vary. Many candidates have prior experience with similar interviews, especially in support-level roles, and may feel confident without additional help. However, if you’d like guidance, we’re here to help you prepare and succeed!</p>
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-6 dkg-interview-prep-right-con col-sm-12">
                    <div className="dkg-youtube-embed">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/htpg8CuD1Ec?si=6UwJS7Yeb30OJdcS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                  </div>
                </div>
                <div className="dkg-interview-prep-sec row" style={{borderBottom: "1px solid #676766"}}>
                  <div className="col-lg-6 col-md-6 dkg-interview-prep-left-con col-sm-12">
                     <h2 className="dkg-interview-prep-heading">How to Access SharePoint Site?</h2>
                     <div className='dkg-interview-prep-order-ul'>
                      <li className='dkg-interview-prep-order-li'>Check your mailbox for the SharePoint site joining invitation. </li>
                      <li className='dkg-interview-prep-order-li'>Verify your identity by adding your email address. </li>
                      <li className='dkg-interview-prep-order-li'>Log in with your email address to SharePoint.</li>
                      <li className='dkg-interview-prep-order-li'>Send a login code request to your mailbox. </li>
                      <li className='dkg-interview-prep-order-li'>Enter the login code from your mailbox.</li>
                      <li className='dkg-interview-prep-order-li'>Save the login credentials for future use.</li>
                      <li className='dkg-interview-prep-order-li'>Give access permissions to Microsoft. </li>
                      <li className='dkg-interview-prep-order-li'>Login interview preparation website.</li>
                      <li className='dkg-interview-prep-order-li'>Add SharePoint site to the mobile.</li>
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-6 dkg-interview-prep-right-con col-sm-12">
                    <div className="dkg-youtube-embed">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/46BP7f-EtgU?si=A2UJJrFMXtVEqzEa" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                  </div>
                </div>
                <div className="dkg-interview-prep-sec row">
                  <div className="col-lg-6 col-md-6 dkg-interview-prep-left-con col-sm-12">
                     <h2 className="dkg-interview-prep-heading">Add SharePoint to Mobile Screen</h2>
                     <div className='dkg-interview-prep-content'>
                      <p>For quick and easy access to this SharePoint site, save it to your mobile browser's "Home Tab" for one-tap convenience. The site's content is optimized for mobile viewing, making it easy to read and navigate. You can access it anytime, anywhere.</p>
                      <p>Important Note: The SharePoint mobile app only works with a Microsoft work or student account. Since you have been added as a "Guest User," the mobile app will not work for you. Instead, use your email to log in via the mobile browser. This home screen tab option will provide a similar experience to the mobile app.</p>
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-6 dkg-interview-prep-right-con col-sm-12">
                    <div className="dkg-youtube-embed">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/UsG3NwsH5IA?si=isSs34Fs8U2hl9KP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         </div>
    </div>
    </>
  )
}

export default InterviewPrep